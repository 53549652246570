<template>
  <v-container class="donate-points">
    <!--<h4 class="text-uppercase mb-1">
      {{ $t("donatePoints.title") }}
    </h4>-->
    <div class="text-body-0">
      {{ $t("donatePoints.subtitle") }}
    </div>
    <h4 class="text-uppercase mb-1">
      {{ $t("donatePoints.form") }}
    </h4>
    <div class="text-body-0">
      {{ $t("donatePoints.formDesc") }}
    </div>
    <v-form ref="form" @submit.prevent="saveData">
      <div class="mt-3">
        <v-text-field
          color="primary"
          v-model="userData.toFidelityCard"
          :label="$t('donatePoints.receiverCard') + ' *'"
          dense
          outlined
          rounded
          :rules="[requiredValue()]"
        ></v-text-field>
      </div>
      <div>
        <v-text-field
          color="primary"
          v-model="receiverCardConfirm"
          :label="$t('donatePoints.confirmReceiverCard') + ' *'"
          dense
          outlined
          rounded
          :rules="cardConfirmRules"
        ></v-text-field>
      </div>
      <div>
        <v-select
          v-model="userData.pointType"
          :items="pointsOptions"
          @change="onChangeWallet"
          dense
          outlined
          rounded
        ></v-select>
      </div>
      <div>
        <v-text-field
          color="primary"
          v-model="userData.points"
          :label="$t('donatePoints.pointsAmount')"
          :hint="hint"
          persistent-hint
          dense
          outlined
          rounded
          :rules="pointsRule"
        ></v-text-field>
      </div>
      <div class="py-3" v-if="responseMessage != ''">
        <ResponseMessage class="mt-3" :response="responseMessage" />
      </div>
      <div>
        <v-btn
          :loading="loading"
          type="submit"
          color="primary"
          large
          block
          depressed
          rounded
        >
          {{ $t("donatePoints.send") }}
        </v-btn>
      </div>
    </v-form>
  </v-container>
</template>

<script>
import { requiredValue, isNumber } from "~/validator/validationRules";
import { mapGetters, mapActions } from "vuex";
import AbbondanzaRegistrationService from "@/service/abbondanzaRegistrationService";
import ResponseMessage from "@/components/ResponseMessage.vue";
import CategoryService from "~/service/categoryService";

export default {
  name: "DonatePoints",
  components: { ResponseMessage },
  data() {
    return {
      loading: false,
      requiredValue: requiredValue,
      pointsRule: [
        isNumber(),
        requiredValue(),
        v =>
          v <= this.userPoints ||
          `Non puoi donare più di ${this.userPoints} punti`
      ],
      cardConfirmRules: [
        v => !!v || "Confermare la carta",
        v => v === this.userData.toFidelityCard || "Le carta non coincidono"
      ],
      userData: {
        pointType: ""
      },
      receiverCardConfirm: null,
      responseMessage: "",
      isBonusAvailable: false,
      pointsOptions: [{ text: "Programma Fedeltà", value: "" }]
    };
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser",
      userPoints: "custom/userPoints"
    }),
    hint() {
      if (this.userData.pointType === "") {
        return `I tuoi punti fedeltà disponibili sono: ${this.userPoints}`;
      } else {
        return `I tuoi punti bonus disponibili sono: ${this.userPoints}`;
      }
    }
  },
  methods: {
    onChangeWallet(value) {
      this.updateUserPoints(value);
    },
    ...mapActions({
      updateUserPoints: "custom/updateUserPoints"
    }),

    async checkBonusAvailability() {
      try {
        const response = await CategoryService.getCategoryBySlug(
          "donate-points"
        );

        const categoryData = response.metaData.category_bolliniElettronici;
        const activationStartDate = categoryData.ATTIVAZIONE_DATA_DA;
        const activationEndDate = categoryData.ATTIVAZIONE_DATA_FINE;
        const displayBollini = categoryData.DISPLAY_BOLLINI;

        if (!activationStartDate) {
          this.isBonusAvailable = false;
          return;
        }

        let today = new Date();
        let startDate = new Date(activationStartDate);
        let endDate = activationEndDate ? new Date(activationEndDate) : null;

        // If endDate is null, it's considered always active after startDate
        this.isBonusAvailable =
          today >= startDate && (!endDate || today <= endDate);

        if (this.isBonusAvailable && displayBollini) {
          const bolliniText = categoryData.TITLE;
          const counterId = categoryData.COUNTER_ID;
          this.pointsOptions.push({ text: bolliniText, value: counterId });
        }
      } catch (error) {
        console.error("Error fetching bonus points availability", error);
        this.isBonusAvailable = false;
      }
    },
    saveData() {
      if (this.$refs.form.validate()) {
        this.responseMessage = "";
        this.loading = true;

        if (this.userPoints < this.userData.points) {
          this.loading = false;

          return;
        }
        AbbondanzaRegistrationService.transferPoints(this.userData)
          .then(data => {
            this.responseMessage = data.response;
          })
          .finally(() => {
            this.updateUserPoints();
            this.loading = false;
            this.userData.points = 0;
          });
      }
    }
  },

  created() {
    this.userData.name = this.user.firstName;
    this.checkBonusAvailability();
  }
};
</script>
